// return the appropriate file extension from a data query string
// assumes presigned url format like "baseurl/filename.extension ? parameters"
// we want to capture the base filename.extension and return it
export const getBaseFileNameFromPresignedurl = (presign_url: string) => {

  // remove parameters from url string
  let stripped_parameters = presign_url.split("?", 1)[0];

  // get extension by capturing last token from split with .
  const split_array = stripped_parameters.split('/');
  const base_file_name = split_array[split_array.length - 1];

  return base_file_name;

};

// pass in a desired filename with extension and a file blob to save the file locally
export const download = (filename: string, fileBlob: Blob) => {
  let element = document.createElement('a');
  element.setAttribute('href', window.URL.createObjectURL(fileBlob));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const getColumns = (show_handle_contact_only: string) => [
  {
    Header: 'Date',
    accessor: 'date'
  },
  ...(show_handle_contact_only === 'true' ? [
    {
      Header: 'Subject ID',
      accessor: 'subjectid',
      disableSortBy: true
    }
  ] : [
    {
      Header: 'User Name',
      accessor: 'userName',
      disableSortBy: true
    },
    {
      Header: 'Contact',
      accessor: 'contact',
      disableSortBy: true
    }
  ]),
  {
    accessor: 'scanId'
  }
];

const padTo2Digits = (num) => {
  return num.toString().padStart(2, '0');
};

export const getDateWithCorrectFormat = (date) => {
  const dateWithCorrectFormat = (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate())
    ].join('-') +
    ' ' +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds())
    ].join(':')
  );
  return dateWithCorrectFormat;
};

type GenerateDataForTableParams = {
  scans: Array<any>
  users: any
  show_handle_contact_only: string
  appId: string
}


export const generateDataForTable = ({scans, users, show_handle_contact_only, appId}: GenerateDataForTableParams) => {
  // filter to only include scans with status Complete
  return scans.reduce((scansArr, scan) => {
    if (scan.status === 'complete'){

      // if email is blank or unknown, use phone as contact field
      let contact = users?.[scan.user].email || users?.[scan.user].phone
      if( contact == "unknown"){
        contact = users?.[scan.user].phone
      }

      scansArr.push({
        utcDate: scan.date,
        date: getDateWithCorrectFormat(new Date(scan.date)),
        ...(show_handle_contact_only === 'true' ? {
          subjectid: users?.[scan.user].handles[appId]?.[0]
        } : {
        userName: `${users?.[scan.user].firstName} ${users?.[scan.user].lastName}`,
        contact: contact
        }),
        scanId: scan.id,
        userId: scan.user
      });
    }
    return scansArr;
  }, []);
};
