import * as React from 'react';
import { useState, useEffect, useContext, useCallback } from 'react';
import { Form, InputGroup, FormControl, Button } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { AppContext, ToastContext } from '..';
import Loading from '../components/Loading';
import { PhoneInputField } from '../components/PhoneInputField';
import { SearchBarProps } from './SearchBar';
import { validatePhoneNumber } from './validations';
import { UsersContext } from './ScanTablePage';
import ReactGA from 'react-ga4';

const useStyles = createUseStyles({
  form: {
    padding: '50px 10px',
    maxWidth: '450px',
    margin: '0 auto'
  }
});

export type SearchType = 'qrcode' | 'email' | 'phone' | 'subjectid';

interface SearchByContactDataProps extends SearchBarProps {
  searchBy: SearchType;
  searchPrompt: string;
}

export const SearchByContactData: React.FC<SearchByContactDataProps> = ({
  searchBy,
  searchPrompt,
  searchHandler: _searchHandler
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFullyLoaded, setIsFullyLoaded] = useState(false);
  const [inputState, setInputState] = useState('');
  const [countryData, setCountryData] = useState<{
    countryCode: string;
    dialCode: string;
  }>({ countryCode: '', dialCode: '' });
  const styles = useStyles();
  const { setToast } = useContext(ToastContext);
  const { id } = useContext(AppContext);
  const { users, fetchNextPage, resetPager } = useContext(UsersContext);

  const getUsersData = useCallback(async () => {
    const findInputStateInUsers = (usersPage) => {
      let user;
      if (searchBy === "email"){
        user = usersPage.find(
          (user) => user.email?.toLowerCase() === inputState
        );
      }
      else if (searchBy === 'subjectid'){
        user = usersPage.find((user) =>
          user.handles[id].find((item) => item.toLowerCase() === inputState)
        );
      }
      else if (searchBy === 'phone'){
        let normalizedInputState = inputState?.replace('+','');
        user = usersPage.find((user) => user.phone?.replace('+','') === normalizedInputState);
      }

      return user;
    };

    if (!inputState) {
      setIsLoading(false);
      return;
    }

    const currentUsers = Object.keys(users).map(function (key) {
      return users[key];
    });
    let user = findInputStateInUsers(currentUsers);
    if (!user) {
      // User paging starts from where it left off. Worst case scenario is a new user that would have
      // appeared in the most recently fetched page. We want to reset paging at most once.
      let pagingReset = false;
      while (true) {
        const usersPage = await fetchNextPage();
        if (!usersPage) {
          if (!pagingReset) {
            pagingReset = true;
            resetPager();
            continue;
          }
          setIsLoading(false);
          setIsFullyLoaded(true);
          break;
        }

        user = findInputStateInUsers(usersPage);
        if (user) break;
      }
    }

    if (!user) {
      setToast({
        text: `No user found for ${inputState}`
      });
      setIsLoading(false);
      return;
    }

    setIsLoading(false);

    _searchHandler(user.id);
  }, [inputState, _searchHandler, searchBy, id, resetPager, setToast]);

  const searchHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const trimmedInputState = inputState.trim();
    if (!trimmedInputState) {
      return setToast({
        text: "This field can't be empty!"
      });
    }

    ReactGA.event('searched_results', {
      method: searchBy
    });

    if (searchBy === 'email' || searchBy === 'subjectid') {
      setInputState(trimmedInputState.toLowerCase());
    } else if (searchBy === 'phone') {
      if (!validatePhoneNumber(inputState, countryData.countryCode)) {
        setToast({
          text: `Phone number isn't valid`
        });
        return;
      }
      setInputState(trimmedInputState);
    }

    setIsLoading(true);
  };

  const emailChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputState(e.target.value);
  };

  const phoneChangeHandler = (phoneNumber: string) => {
    setInputState(phoneNumber);
  };

  useEffect(() => {
    if (isLoading) {
      getUsersData().catch(console.error);
    }
  }, [isLoading, getUsersData]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Form className={styles.form} onSubmit={searchHandler}>
      <h4>Enter user {searchPrompt}</h4>
      <InputGroup className="mb-3">
        {searchBy === 'phone' ? (
          <PhoneInputField
            value={inputState}
            handleChange={phoneChangeHandler}
            setCountryData={setCountryData}
            ariaLabel="Recipient's phone number"
            ariaDescribedby="basic-addon2"
          />
        ) : (
          <FormControl
            onChange={emailChangeHandler}
            value={inputState}
            name={searchBy}
            placeholder={searchPrompt}
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
          />
        )}
        <Button type="submit">Search</Button>
      </InputGroup>
    </Form>
  );
};
